import './TrainingCenterPage.scss'

import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxColumns, {
  PuxMainColumn,
  PuxSideColumn,
} from 'Shared/components/PuxColumns/PuxColumns'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxRepeater, {
  TaxonomyContentItemType,
} from 'Shared/components/PuxRepeater/PuxRepeater'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

import Layout from '../../components/core/Layout'

export interface TrainingCenterType {
  contentType: string
  path: string
  contentItemId: string
  contentItemVersionId: string
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
}

export interface TrainingCenterPageType extends PageProps {
  data: {
    orchard: {
      easySoftwareTrainingCenter: [TrainingCenterType]
      easySoftwareVideoTutorial: TaxonomyContentItemType[]
    }
  }
  pageContext: {
    pagePath: string
    widgets: {
      topWidgetZone: [WidgetPuxSectionType]
      bottomWidgetZone: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    sideCardWidgets: any
  }
}

export const query = graphql`
  query($pagePath: String = "", $isoCode: String = "en-GB") {
    orchard {
      easySoftwareTrainingCenter(first: 1, where: { path: $pagePath }) {
        contentType
        path
        contentItemId
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareTrainingCenter {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
      easySoftwareVideoTutorial(
        where: { localization: { culture: $isoCode } }
      ) {
        contentType
        contentItemId
        displayText
        createdUtc
        videoTutorialSolution
        videoTutorialLevel
        videoTutorialRole
        videoTutorialPerex {
          html
        }
        puxVideo {
          videoStructuredDataContentUrl
          videoStructuredDataDescription
          videoStructuredDataName
          videoStructuredDataUploadDate
          videoYTID {
            html
          }
        }
        videoTutorialImage {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
            texts
          }
        }
      }
    }
  }
`

const rootClass = `training-center-page`

const TrainingCenterPage: FunctionComponent<TrainingCenterPageType> = (
  props
) => {
  const data = props.data.orchard.easySoftwareTrainingCenter[0]
  const widgets = props.pageContext.widgets
  const breadcrumbsData = props.pageContext.breadcrumbs

  return (
    <Layout localizedPath={data.localization?.localizations}>
      {/* <EasySoftwareBreadcrumbs  items={breadcrumbsData} /> */}
      <PuxMetaTags {...props.pageContext.metaTags} />
      <div className={rootClass}>
        {/* <div className={rootClass + `-widgets`}>
        </div> */}
        {/* <div className='pux-container'>
          <PuxColumns>
            <PuxMainColumn> */}
        <WidgetBuilder widgetBuilderData={widgets.topWidgetZone} />
        {/* <PuxRepeater
                repeaterData={props.data.orchard.easySoftwareVideoTutorial}
                simpleRepeaterOnePageOnly={false}
                simpleRepeaterPageSize={6}
                simpleRepeaterPagerOptions={`pages`}
                contentItemId={`training-center`}
                puxSimpleFilter={{ puxFilterIsHidden: false }}
              /> */}
        {/* </PuxMainColumn>
            <PuxSideColumn>
              <div className='sidebar-sticky'>
                <WidgetBuilder
                  widgetBuilderData={props.pageContext.sideCardWidgets}
                />
              </div>
            </PuxSideColumn>
          </PuxColumns>
      </div> */}
      </div>
    </Layout>
  )
}

export default TrainingCenterPage
